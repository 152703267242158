import React, {useState} from "react";
import "./Carousel.css";
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from "react-icons/bs"
import entrada from '../../img/entrada.jpeg'
import oficina from '../../img/oficina.jpg'
import imagem1 from '../../img/IMG_7931.jpg'
import imagem2 from '../../img/IMG_7932.jpg'
import imagem3 from '../../img/IMG_7937.jpg'
import imagem4 from '../../img/IMG_7936.jpg'
import imagem5 from '../../img/IMG_7926.jpg'
import imagem6 from '../../img/IMG_7925.jpg'
import imagem7 from '../../img/IMG_8004.jpg'
import imagem8 from '../../img/IMG_8005.jpg'
import imagem9 from '../../img/IMG_8006.jpg'
import imagem10 from '../../img/meca_entrada.jpeg'
const Carousel = () => {

    const images = [entrada, oficina, imagem1, imagem2, imagem3, imagem4, imagem5,imagem6, imagem7, imagem8, imagem9, imagem10]
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === images.length - 1 ? 0 : slide + 1);
    }

    const prevSlide = () => {
         setSlide(slide === 0 ? images.length - 1 : slide - 1);
    }


    return (
        <div className="carousel">
            <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
            {images.map((item, idx) => {
                return (
                <img id="imagem-carrousel" src={images[idx]} alt={item.alt} key={idx} className={slide === idx ? "slide" : "slide slide-hidden"}></img>
                );
            })}
            <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
            <span className="indicators">
                {images.map((_, idx) => {
                return <button key={idx} onClick={null} className={slide === idx ? "indicator" : "indicator indicator-inactive"}></button>
                })}
            </span>
        </div>
        
    )
}
export default Carousel;