import "./Pic.css";
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from "react"
import bmwLogo from "../../img/bmw2.png"
import mercedesLogo from "../../img/mercedes2.png"
import porscheLogo from "../../img/porsche2.png"



const Pic = () => {

    useEffect (()=> {
        AOS.init({duration: 600});
    }, []);

    return(
        <div className="pic">
            <div className="tipografia">
                    <section className="content">
                        <h1 data-aos="fade-right" >Soluções automotivas em SC e SP</h1>
                        <h2 data-aos="fade-right">Assistência técnica especializada</h2>
                        <div className="logos" data-aos="fade-right">
                            <img src= {bmwLogo} alt="bmw logo" ></img>
                            <img className="mercedes" src= {mercedesLogo} alt="mercedes logo"></img>
                            <img src= {porscheLogo} alt="porsche logo"></img>
                        </div>
                    </section>
            </div>
        </div>
        
    );
}
export default Pic;