import './Mapa.css';
import { useRef, useEffect,useState } from 'react';

const Mapa = () => {
  const mapaDivRef = useRef(null);
  const [iframeWidth, setIframeWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (mapaDivRef.current) {
        setIframeWidth(mapaDivRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mapaDivRef]);

  return (
    <div ref={mapaDivRef} className="mapa">
      <h1 data-aos="zoom-in">Onde nos encontrar:</h1>
      <p data-aos="zoom-in"><strong>Endereço</strong>: R. 2550, 883 - Centro, Balneário Camboriú - SC, 88330-390</p>
      <p data-aos="zoom-in"><strong>Horário de atendimento</strong>: Segunda - Sexta, das 08:00 - 18:30.</p>
      <iframe
        title="mapa-google"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14219.82040866695!2d-48.6354335!3d-26.99997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce43e3709765f1%3A0x14252db1947a63f9!2sOficina%20Mec%C3%A2nica%20Stuttgart%20Motors!5e0!3m2!1spt-BR!2sbr!4v1721661865892!5m2!1spt-BR!2sbr"
        width={iframeWidth}
        height={0.5*iframeWidth}
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default Mapa;