import './footer.css';


const Footer = () => {
    return(
        <div className='footer'>
            <p>Desenvolvido por <a href="https://www.brunyou.net" target="_blank" rel="noreferrer"><strong>Bruno Pereira</strong></a></p>
        </div>
    );
}

export default Footer;