import certificado from "../../img/certificados.jpeg"
import { TransformWrapper, TransformComponent} from "react-zoom-pan-pinch"
import './certificados.css'


const Certificados = () => {
    return(
        <div data-aos="zoom-in" className="certificados">
            <h1>Painel de certificados</h1>
            <p>Sinta-se livre para dar zoom</p>
            <TransformWrapper defaultScale={1} defaultPostionX={100} defaultPostionY={100}>
                <TransformComponent>
                    <img src={certificado} alt="certificados"></img>
                </TransformComponent>
            </TransformWrapper>
        </div>
  );
}

export default Certificados;