import './Links.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';
const Links = () => {
    return(
        <div className="links">
            <h1 data-aos="zoom-in">Links úteis</h1>
            <p data-aos="zoom-in">Aqui você encontra alguns links que podem te ajudar</p>
            <ul>
            <li><a href="https://www.portalmercedes.com/forum" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowPointer}  style={{color: "#FFFF",}} size="2xl"></FontAwesomeIcon> Portal Mercedes-Benz Brasil</a></li>
            <li><a href="https://forummercedes.forumeiros.com/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowPointer}  style={{color: "#FFFF",}} size="2xl"></FontAwesomeIcon> Fórum Mercedes-Benz Brasil</a></li>
            <li><a href="https://www.lastvin.com/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowPointer}  style={{color: "#FFFF",}} size="2xl"></FontAwesomeIcon> LastVin VIN Decoder</a></li>
            </ul>

        </div>
    );
}

export default Links