import Logo from "../../img/logo.png"
import '../NavBar/Navbar.css'
import React,{useState, useEffect} from "react"

const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (menuOpen &&!event.target.closest('#navbar')) {
          setMenuOpen(false)
        }
      }
      document.addEventListener('click', handleOutsideClick)
      return () => {
        document.removeEventListener('click', handleOutsideClick)
      }
    }, [menuOpen])

    return(
        <nav id='navbar'>
            <a id='logo' href="#Home"><img src={Logo} alt='Logo Stuttgart' className='logo'></img></a>
            <div className='menu' onClick={() => {
                setMenuOpen(!menuOpen);
            }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={menuOpen ? "open" : ""}>
                <li><a className='navbutton' href='#Servicos'>SERVIÇOS</a></li>
                <li><a className='navbutton' href='#Sobre'>SOBRE</a></li>
                <li><a className='navbutton' href='#Certificados'>CERTIFICADOS</a></li>
                <li><a className='navbutton' href='#Contatos'>CONTATOS</a></li>
                <li><a className='navbutton' href='#Endereco'>ENDEREÇO</a></li>
                <li><a className='navbutton' href='#Links'> UTILITÁRIOS</a></li>
                <li><a className='navbutton' href='https://hotmart.com/pt-br/marketplace/produtos/curso-dos-programas-de-diagnostico-e-biliotecas-de-dados-mercedes-benz/X79085557V' target="blank_">CURSOS e LITERATURA</a></li>
            </ul>
            
        </nav>
    )
}

export default NavBar;