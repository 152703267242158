import './App.css';
import Home from './components/Home';
import NavBar from './components/NavBar';
import GoogleReviews from './components/GoogleReviews';
import Sobre from './components/Sobre';
import Mapa from './components/Mapa';
import Contatos from './components/Contatos';
import Pic from './components/Pic';
import Footer from './components/footer'
import Links from './components/Links';
import Certificados from './components/Certificados';


function App() {
  return (
    <div className="App">
    <NavBar id='navbar'/>
    <section id='Home'><Pic/></section>

    <section className='body'>
    <section id='Servicos'><Home/></section>
    <section id='Sobre'><Sobre/></section>
    <section id='Certificados'><Certificados/></section>
    <section id='Contatos'><Contatos/></section>
    <GoogleReviews/>
    <section id='Endereco'><Mapa/></section>
    <section id='Links'><Links/></section>
    </section>
    <Footer/>
    
    
    
    
    
    
    
    
  
    </div>
  );
}

export default App;
