import "./Home.css"
import React from 'react'
import Servicos from "../Servicos"




const Home = () => {
return (
    
    <section id='home' >
        <div data-aos="zoom-in">
          <Servicos data-aos="fade-right"/>
        </div>
    </section>
    
)
}

export default Home;