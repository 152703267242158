import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import './Contatos.css';

const Contatos = () => {
    return(
        <div className='contato'>
        <h1 data-aos="zoom-in"> Contatos </h1>
        <p data-aos="zoom-in">Estamos a um clique de distância, basta apertar em uma das opções para entrar em contato!</p>
         <ul>
            <li><a href='https://wa.me/4788573831' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} style={{color: "#FFFF",}} size="2xl"/> WhatsApp Oficina</a>  </li>
            <li><a href='https://wa.me/11957310812'  target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} style={{color: "#FFFF",}} size="2xl"/> WhatsApp Vander Fioretti</a> </li>
            <li><a href='https://www.instagram.com/stuttgartmotorsespecializada/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faInstagram} style={{color: "#FFFF",}} size="2xl"/> Instagram</a></li>
            <li><a href='mailto:stuttgartmotors08@gmail.com' target='_blank'  rel="noreferrer" ><FontAwesomeIcon icon={faEnvelope} style={{color: "#FFFF",}} size="2xl" /> Email</a></li>
         </ul>
         </div>
    )
}

export default Contatos;