import './Sobre.css';
import Carousel from '../Carousel';
const Sobre = () => {
    return(
        <section className="sobre" >
       
            <h1 >Sobre nós</h1>
            <div data-aos="zoom-in">
            <p> Ordenado por Vander W. Fioretti, conhecido no mercado como o "Mestre das Mercedes" 
                devido a experiência e atuação desde 1990 dentro do segmento. 
                Proprietário de uma "Oficina-Modelo" na cidade de Balneário Camboriú-SC, 
                onde além de assistência técnica VIP aos clientes, ministra treinamentos, 
                mentorias e consultoria técnica para profissionais da área 
                e gestores de oficinas que desejam se especializar 
                e transformar a empresa em uma oficina premium com o mais alto padrão 
                de qualidade seguindo os processos e protocolos conforme os princípios da montadora.
            </p>
            <Carousel/>
            </div>
        </section>
        
    );
}

export default Sobre;